import React from 'react'
import Header from '../components/Ui/Header'
import { useTranslation } from 'react-i18next'
import Section from '../components/Ui/Section'
import { Box, Button, CardMedia, Typography, useMediaQuery } from '@mui/material'
import Services from '../components/ServicesPage/Services'
import serv from './../assets/Services/0.png'
import { serviceMove } from '../constants'
import i18next from 'i18next'
import './Services.css'
import { useNavigate } from 'react-router-dom'
const ServicesMove = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    let lng = i18next.language
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div>
            <Header title={t("linksPages.OurServices")} />
            <Section>
                <Services
                    sliderItem={<CardMedia
                        component="img"
                        height="auto"
                        image={serv}
                        alt="green iguana"
                        sx={{
                            height: '450px',
                            borderRadius: '18px',
                            objectFit: 'fill',
                            width: { md: '100%', xs: '100%' }
                        }}
                    />}
                    // srcImg={serv}
                    // height={'450px'}
                    title={t("Service.service1")}
                    desc1={t("Service.desc")}>

                    {
                        serviceMove.map((serviceMove) =>
                            <Box sx={{ display: 'flex', gap: 1 }} >
                                <span className='step'></span>
                                <Typography variant={isSmallScreen ? 'dev' : 'dev'} >{lng === 'en' ? serviceMove.servE : serviceMove.serv}</Typography>
                            </Box>
                        )
                    }
                    <Button sx={{
                        my: 2,
                        backgroundColor: '#F9AF33',
                        color: '#fff',
                        "&:hover": {
                            backgroundColor: '#F9AF33', color: "#fff",
                        },
                        width: 'fit-content',
                        py: 1,
                        px: 4
                    }}
                        onClick={() => navigate('/contact')}
                    >
                        {t("linksPages.ContactUs")}<br />

                    </Button>
                </Services>
            </Section>
        </div>
    )
}

export default ServicesMove