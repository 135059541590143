import React from 'react'
import ListOurFeatures from './ListOurFeatures'
import Section from '../Ui/Section'

const OurFeatures = () => {
    return (
        <div style={{ backgroundColor: '#FAFAFA', padding: '16px 0' }} >
            <Section>
                <ListOurFeatures />
            </Section>
        </div>
    )
}

export default OurFeatures