import { Box, Container, Typography } from "@mui/material";
import React from "react";

function Section({ children, title }) {
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    marginTop: '60px',
                    marginBottom: 2,
                }}
            >
                <Typography
                    variant="h5"
                    component="h4"
                    sx={{
                        mb: 2,
                        fontWeight: "null",
                        color: "#000",
                        textAlign: "center"
                    }}
                >
                    {title}
                </Typography>
                {children}
            </Box>
        </Container>
    );
}

export default Section;
