import React from 'react'
import './ScrollToTop.css'
import i18next from 'i18next';
function ScrollTo() {
    let lng = i18next.language
    React.useEffect(() => {
        let span = document.querySelector(".up");
        window.onscroll = function () {
            this.scrollY >= 1000 ? span.classList.add("show") : span.classList.remove("show");
        };
        span.onclick = function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
    }, []);
    const bottomToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <span onClick={bottomToTop} className={lng === 'ar' ? 'up' : 'up'}>
            &#8593;
        </span>
    )
}

export default ScrollTo