
import { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ScrollTo from './components/Ui/ScrollToTop';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Footer from './components/footer/Footer';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import ServicesMove from './pages/ServicesMove';
import ServicesStorage from './pages/ServicesStorage';
import FreeInspection from './components/Ui/FreeInspection';
import SotialFooter from './components/glopal/SotialFooter';

const languages = [
  {
    code: 'ar',
    name: 'Ar',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'En',
    country_coode: 'gb',
    dir: 'ltr'
  }
]

function App() {

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  const { t } = useTranslation();
  useEffect(() => {

    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');
    // document.title = t('app_title'); 

  }, [currentLanguage, t,]);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <div className="App">
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KC8SXNSP"
        height="0" width="0" title='camal' style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <ScrollTo />
      <FreeInspection />
      <SotialFooter />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services-move" element={<ServicesMove />} />
        <Route path="/services-storage" element={<ServicesStorage />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
