import React from 'react'
import { Box, Button, CardMedia, Typography } from '@mui/material';
import Carousel from "react-material-ui-carousel";
import header1 from './../../assets/Home/HeaderSlider/5.png'
import header3 from './../../assets/Home/HeaderSlider/5.png'
import header4 from './../../assets/Home/HeaderSlider/5.png'
import header5 from './../../assets/Home/HeaderSlider/5.png'
import './Header.css'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    return (
        <>
            <Box position={'relative'} >
                <div className="overlay"></div>
                <Carousel
                    indicators={true}
                    indicatorContainerStyle={{ position: 'absolute', bottom: '50px', zIndex: '9999' }}
                    sx={{
                        width: "100%",
                        height: { md: "85vh", xs: "300px" },
                        margin: "auto"
                    }}>
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header1}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header3}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header4}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                    <CardMedia
                        sx={{ height: { md: "85vh", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header5}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }} />
                </Carousel>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    padding: 2,
                    transform: 'translate(-50%, -50%)', zIndex: 999,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    alignItems: "center",
                    width: "100%",
                }} >
                    <Typography variant='h4' sx={{ color: '#F9AF33', fontWeight: '500', my: 1 }}>
                        {t("sliderText.title")} <br />
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: '#fff' }}  >
                        {t("sliderText.supTitle")}<br />
                        {t("sliderText.supTitle2")}
                    </Typography> <br />
                    <Button sx={{
                        backgroundColor: '#F9AF33',
                        color: '#fff',
                        "&:hover": {
                            backgroundColor: '#fff', color: "#F9AF33",
                        },
                        width: 'fit-content',
                        py: 1,
                        px: 4
                    }}
                        onClick={() => navigate('/contact')}
                    >
                        {t("sliderText.ReadMore")}<br />

                    </Button>
                </Box>
            </Box>
        </>
    )
}

export default Header
