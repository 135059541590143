import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./util/theme";
import './locales/i18n';
import { SnackbarProvider } from "notistack";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          style={{ fontSize: '1rem' }}
          variant="success"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
          <App />

        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

