import React from 'react'
import Carousel from 'react-material-ui-carousel'
import a1 from './../../assets/album/IMG-20240106-WA0027.jpg'
import a2 from './../../assets/album/IMG-20240106-WA0028.jpg'
import a3 from './../../assets/album/IMG-20240106-WA0029.jpg'
import a4 from './../../assets/album/IMG-20240106-WA0030.jpg'
import a5 from './../../assets/album/IMG-20240106-WA0031.jpg'
import a6 from './../../assets/album/IMG-20240106-WA0032.jpg'
import a7 from './../../assets/album/IMG-20240106-WA0033.jpg'
import a8 from './../../assets/album/IMG-20240106-WA0034.jpg'
const Album = () => {
    return (
        <>
            <Carousel
                height={500}
                sx={{
                    marginTop: "25px",
                    width: { md: "40%", xs: "100%" },
                    margin: "auto"
                }}>

                <img
                    fetchpriority="high"
                    src={a1}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a2}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a3}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a4}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a5}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a6}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a7}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
                <img
                    fetchpriority="high"
                    src={a8}
                    alt=""
                    style={{
                        height: 500,
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "fill",
                    }}
                />
            </Carousel>
        </>
    )
}

export default Album