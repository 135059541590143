import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'

const SectionAbout = ({ derRow, derCol, background, srcImg, title, desc1, desc2, desc3, desc4 }) => {
    return (
        <Container maxWidth={'lg'} >

            <Box sx={{
                marginTop: '60px',
                marginBottom: 2,
                display: 'flex',
                flexDirection: { md: derRow, xs: derCol },
                gap: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                background: background
            }} >
                <CardMedia
                    component="img"
                    height="300"
                    image={srcImg}
                    alt="green iguana"
                    sx={{ borderRadius: '18px', objectFit: 'fill' }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }} >
                    <Typography variant='h5' sx={{ color: '#FFC82D' }}>{title}</Typography>
                    <Typography sx={{ color: '#000' }} >{desc1}</Typography>
                    <Typography sx={{ color: '#939494' }} >{desc2}</Typography>
                    <Typography sx={{ color: '#939494' }} >{desc3}</Typography>
                    <Typography sx={{ color: '#939494' }} >{desc4}</Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default SectionAbout