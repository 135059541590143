import { Box, CardMedia } from '@mui/material'
import React from 'react'

const CardReview = ({ srcImg }) => {
    return (
        <Box sx={{
            border: '3px dotted #F9AF33',
            padding: '5px',
            borderRadius: '8px',
            width: { md: '23%', xs: '100%' }
        }} >
            <CardMedia
                component="img"
                height="250"
                image={srcImg}
                alt="green iguana"
                sx={{
                    borderRadius: '8px',
                    width: '100%',
                    height: '100%',
                    padding: '8px',
                    backgroundColor: 'transparent',
                    border: '3px solid #F9AF33',
                    objectFit: 'contain'
                }}
            />
        </Box>
    )
}

export default CardReview