
import s1 from './../assets/Home/service/s1.jpg'
import s2 from './../assets/Home/service/s2.jpg'

import f1 from './../assets/Home/OurFeatures/padlock.png'
import f2 from './../assets/Home/OurFeatures/fast-delivery (1).png'
import f3 from './../assets/Home/OurFeatures/24-hours-support.png'

import st1 from './../assets/Home/OurStatistics/Icon awesome-hand-peace.png'
import st2 from './../assets/Home/OurStatistics/Icon awesome-expand-arrows-alt.png'
import st3 from './../assets/Home/OurStatistics/Icon awesome-user-friends.png'

import r1 from './../assets/Home/Reviews/35.png'
import r2 from './../assets/Home/Reviews/77.png'
import r3 from './../assets/Home/Reviews/Capture.png'
import r4 from './../assets/Home/Reviews/Capture5.png'
import rE1 from './../assets/Home/Reviews/IMG-20240105-WA0002.jpg'
import rE2 from './../assets/Home/Reviews/IMG-20240105-WA0004.jpg'
import rE3 from './../assets/Home/Reviews/IMG-20240105-WA0005.jpg'
import rE4 from './../assets/Home/Reviews/IMG-20240105-WA0006.jpg'

import servS1 from './../assets/Services/IMG-20240107-WA0002.jpg'
import servS2 from './../assets/Services/IMG-20240107-WA0003.jpg'
import servS3 from './../assets/Services/IMG-20240107-WA0004.jpg'
import servS4 from './../assets/Services/IMG-20240107-WA0005.jpg'
import servS5 from './../assets/Services/IMG-20240107-WA0006.jpg'

export const services = [
        {
                id: 1,
                imageUrl: s1,
                title: `Moving home and office furniture.`,
                supTitle: ` We, the Camel Express, own the largest fleet of cars for transporting furniture between all the cities of the Kingdom,`,
                titleAr: `نقل أثاث المنازل والشركات`,
                supTitleAr: `لدينا كل الامكانيات الخاصة لنقل أثاث شركتك بأعلى جودة
 واقل مجهود`,
                path: '/services-move'
        },
        {
                id: 2,
                imageUrl: s2,
                title: `Storage`,
                supTitle: `Our goal at Camel Express Company is to provide the finest and best service in an integrated manner that suits our customers,`,
                titleAr: `التخزين:`,
                supTitleAr: `هدفنا  في شركة الجمال السريعة توفير أجود وأفضل خدمة على نحو متكامل يليق بعملائنا `,
                path: '/services-storage'
        },
];
export const OurFeatures = [
        {
                id: 1,
                imageUrl: f1,
                title: `Safe & Secure`,
                supTitle: `Our safe and secure moving furniture service ensures that your belongings are handled with utmost care and protection.`,
                titleAr: `الأمان والحماية`,
                supTitleAr: `لدينا فريق عمل متكامل يضمن لك الحماية والأمان 
في نقل أثاثك بشكل سليم`,
        },
        {
                id: 2,
                imageUrl: f2,
                title: `Fast Delivery`,
                supTitle: `Our fast-delivery moving furniture service is designed to provide you with a seamless and efficient experience.`,
                titleAr: `الشحن السريع`,
                supTitleAr: `لدينا الخبرة الكاملة لنقل أثاثك بشكل سريع 
وبجهد أقل`,
        },
        {
                id: 3,
                imageUrl: f3,
                title: `24/7 Support`,
                supTitle: `Our 24/7 support moving furniture service is here to provide you with round-the-clock assistance for all your furniture relocation needs.`,
                titleAr: `دعم متواصل 24/7`,
                supTitleAr: `نوفر لك خدمة الدعم الفني طوال الأسبوع 
وعلى مدار 24 ساعة متواصل لخدمتك في أي وقت`,
        },
];
export const OurStatistics = [
        {
                id: 1,
                imageUrl: st1,
                title: `Customer satisfaction`,
                supTitle: `99%`,
                titleAr: `رضاء العملاء`,
                supTitleAr: `99%`,
        },
        {
                id: 2,
                imageUrl: st2,
                title: `Transfers`,
                supTitle: `7435`,
                titleAr: `عمليات النقل`,
                supTitleAr: `7435`,
        },
        {
                id: 3,
                imageUrl: st3,
                title: `Our customers`,
                supTitle: `6520`,
                titleAr: `عملاؤنا`,
                supTitleAr: `6520`,
        },
];
export const reviews = [
        {
                id: 1,
                imageUrl: r1,
                imageUrlE: rE1,
        },
        {
                id: 2,
                imageUrl: r2,
                imageUrlE: rE2,
        },
        {
                id: 3,
                imageUrl: r3,
                imageUrlE: rE3,
        },
        {
                id: 4,
                imageUrl: r4,
                imageUrlE: rE4,
        },
];
export const serviceMove = [
        {
                id: 1,
                serv: ` يتوفر لدينا طاقم متكامل من الفنيين والعمالة المدربة لكافة أعمال نقل الأثاث .`,
                servE: `  We have an integrated staff of technicians and trained workers for all furniture moving work.`,
        }, {

                id: 2,
                serv: `نقوم بتجهيز عملية النقل كاملة فك جميع غرف المنزل و الستائر و التكييفات و كافة أغراض المنزل و المكتب .`,
                servE: `We prepare the complete transportation process, dismantling all the rooms of the house, curtains, air conditioners, and all home and office items.`,
        },
        {

                id: 3,
                serv: `نقوم بترتيب الأثاث داخل المنزل بكل عناية لضمان عدم الخدوش.`,
                servE: ` We arrange the furniture inside the house with great care to ensure no scratches.`,
        },
        {
                id: 4,
                serv: ` يقوم الفريق المتخصص بتغليف كافة محتويات النقل بطرق أمنه.`,
                servE: `  The specialized team packs all transportation contents in safe ways.`,
        },
        {
                id: 5,
                serv: ` يمكننا تعبئة كل أدوات المطبخ و المكتب بالطريقة التى تحافظ عليه سليما بإستخدام كراتين أو صناديق بلاستيكية حسب رغبة العميل.`,
                servE: `  We can pack all kitchen and office utensils in a way that keeps them intact, using cartons or plastic boxes according to the customer’s desire.`,
        },
        {
                id: 6,
                serv: `تتم عملية التحميل والنقل بدقة وأمان للحفاظ على محتويات المنقولات`,
                servE: ` The loading and transportation process is carried out accurately and safely to preserv the contents of the transported items.`,
        },
        {
                id: 7,
                serv: `نقوم بتركيب كافة محتويات النقل على يد فنيين محترفين.`,
                servE: ` We install all transportation contents by professional technicians.`,
        },
        {
                id: 8,
                serv: `يمكننا أن نقدم لك أفضل خدمات الشحن الدولى فى المملكة العربية السعودية لدول مجلس التعاون الخليجي و الإتحاد الأوروبي و أمريكا.`,
                servE: `We can provide you with the best international shipping service in the Kingdom of Saudi Arabia to the Gulf Cooperation Council countries, the European Union, and America.`,
        },
        {
                id: 9,
                serv: `نوفر لك ضمان لمدة عام على عملية الفك والتركيب.`,
                servE: ` We provide you with a one-year warranty on the dismantling and installation process.`,
        },
        {
                id: 10,
                serv: `نقدم خدمة عملاء وفريق عمل على مدار ٢٤ساعة لخدمتكم بشكل فورى وفعال.`,
                servE: ` We provide 24-hour customer servic and staff to serv you promptly and effectively.`,
        }
]
export const serviceStorage = [
        {
                id: 1,
                imageUrl: servS1,
                serv: `  مستودعات أمنه ومجهزة لتخزين الأثاث المنزلي والمكتبي بكافة معايير السلامة والأمان.`,
                servE: `  Safe warehouses equipped to store home and office furniture with all safety and security standards.`,
        },
        {
                id: 2,
                imageUrl: servS2,
                serv: `مراعاة التهوية السليمة و النظافة.`,
                servE: `Taking into account proper ventilation and hygiene.`,
        },
        {
                id: 3,
                imageUrl: servS3,
                serv: ` إتباع النظام و الترتيب للحفاظ علي محتويات المنقولات.`,
                servE: `  Follow the system and arrangement to preserve the contents of movables.`,
        },
        {
                id: 4,
                imageUrl: servS4,
                serv: `تذويدها بكافة وسائل الأمان للوقاية من الحرائق و الحفاظ علي سلامة المخزونات.`,
                servE: `  Providing it with all safety means to prevent fires and maintain the safety of stocks.`,
        },
        {
                id: 5,
                imageUrl: servS5,
                serv: `نقوم بتغليف كل شىء والتعبئة بالطريقة التي تحافظ عليه سليما بمواد خاصة بعملية التخزين.`,
                servE: `We wrap everything and pack it in a way that keeps it intact with special materials for the storage process.`,
        },

]
