import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ContactFooter = () => {
    const { t } = useTranslation()
    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
            <Typography sx={{ color: '#000', my: 1 }} variant='h6' >
                {t("Footer.tit3")}
            </Typography>
            <Typography sx={{ color: '#000' }} >
                {t("Footer.tit4")}
            </Typography>
            <Typography sx={{ color: '#fff' }} >
                {t("Footer.location")}
            </Typography>
            <Typography sx={{ color: '#fff' }} >
                {t("Footer.location2")}
            </Typography>
            <Typography sx={{ color: '#000' }} >
                {t("Footer.nomper")}
            </Typography>
            <Typography sx={{ color: '#fff' }} >
                <a href="tel:920007123" style={{ textDecoration: 'none', color: '#FFF' }} >
                    920007123
                </a>
            </Typography>
            {/* <Box sx={{ display: 'flex', gap: 2 }} >
                <Box>
                </Box>
            </Box> */}
        </Box>
    )
}

export default ContactFooter