import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import TransLang from "./TransLang"
import { useTranslation } from "react-i18next";
import logo from "./../../assets/4.png"
import MenusServices from "./MenusServices";
function NavBar() {
    const { t } = useTranslation()
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: "#F9AF33",
                    padding: "10px"
                }} >
                <Container>
                    <Toolbar disableGutters>
                        <Link
                            to="/"
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                            }}
                        >
                            <img src={logo} height={75} style={{ width: '100px', objectFit: 'cover' }} alt="logo"></img>
                        </Link>

                        <Box sx={{
                            flexGrow: 1,
                            display: { md: "flex", xs: "none" },
                            marginInlineStart: 8,
                            alignItems: "center", justifyContent: 'space-around'
                        }} >
                            <Box
                                sx={{
                                    display: { md: "flex", xs: "none" },
                                    gap: 7,
                                    width: '100%',
                                    justifyContent: 'center',

                                }}>
                                <Link
                                    to="/"
                                    style={{
                                        color: "#FFF",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t('linksPages.home')}
                                </Link>
                                <Link
                                    to="/about"
                                    style={{
                                        color: "#FFF",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t('linksPages.aboutUs')}
                                </Link>
                                <MenusServices />

                                <Link
                                    to="/contact"
                                    style={{
                                        color: "#FFF",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t('linksPages.ContactUs')}
                                </Link>
                            </Box>
                            <TransLang />
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,

                                display: { md: "none", xs: "flex" },
                                justifyContent: "end",
                            }}
                        >
                            <IconButton onClick={() => setDrawer(true)}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
