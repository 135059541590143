import { Box } from '@mui/material'
import React from 'react'
import CardOurFeatures from './CardOurFeatures'
import i18next from 'i18next'
import { OurFeatures } from '../../constants'

const ListOurFeatures = () => {
    let lng = i18next.language
    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap', gap: 3.2,
                justifyContent: 'space-around'
            }} >
                {
                    OurFeatures.map((feature) =>
                        <CardOurFeatures
                            srcImg={feature.imageUrl}
                            title={lng === 'en' ? feature.title : feature.titleAr}
                            supTitle={lng === 'en' ? feature.supTitle : feature.supTitleAr}
                        />
                    )}
            </Box>
        </div>
    )
}

export default ListOurFeatures