import React from 'react'
import './Header.css'
import { Box, Typography } from '@mui/material'
const Header = ({ title }) => {

    return (
        <div className='header' >
            <div className="overlay"></div>
            <Box sx={{ position: 'relative', zIndex: '9' }} >
                <Typography variant='h4' sx={{
                    color: "#FFC82D",
                    fontWeight: 'bold', mb: 2
                }} >
                    {title}
                </Typography>
            </Box>
        </div>
    )
}

export default Header