import React from 'react'
import Header from '../components/Ui/Header'
import Section from '../components/Ui/Section'
import Services from '../components/ServicesPage/Services'
import { Box, Button, CardMedia, Typography, useMediaQuery } from '@mui/material'
import { serviceStorage } from '../constants'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import './Services.css'
import { useNavigate } from 'react-router-dom'
const ServicesStorage = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    let lng = i18next.language
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <>
            <Header title={t("linksPages.OurServices")} />
            <Section>
                <Services
                    sliderItem={
                        serviceStorage.map((item) =>
                            <CardMedia
                                component="img"
                                height="auto"
                                image={item.imageUrl}
                                alt="green iguana"
                                sx={{
                                    height: '350px',
                                    borderRadius: '18px',
                                    objectFit: 'fill',
                                    width: { md: '100%', xs: '100%' }
                                }}
                            />
                        )
                    }
                    // srcImg={serv}
                    // height={'350px'}
                    title={t("Service.service2")}
                    desc1={t("Service.desc")}>
                    {
                        serviceStorage.map((serviceStorage) =>
                            <Box sx={{ display: 'flex', gap: 1 }} >
                                <span className='step'></span>
                                <Typography variant={isSmallScreen ? 'dev' : 'dev'} >{lng === 'en' ? serviceStorage.servE : serviceStorage.serv}</Typography>
                            </Box>
                        )
                    }
                    <Button sx={{
                        my: 2,
                        backgroundColor: '#F9AF33',
                        color: '#fff',
                        "&:hover": {
                            backgroundColor: '#F9AF33', color: "#fff",
                        },
                        width: 'fit-content',
                        py: 1,
                        px: 4
                    }}
                        onClick={() => navigate('/contact')}
                    >
                        {t("linksPages.ContactUs")}<br />

                    </Button>
                </Services>
            </Section>
        </>
    )
}

export default ServicesStorage