import { Box } from '@mui/material'
import React from 'react'
import { OurStatistics } from '../../constants'
import AnimatedCounter from './AnimatedCounter'

const LestOurStatistics = () => {
    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap', gap: 3.2,
                justifyContent: 'space-around'
            }} >
                {OurStatistics.map((statistic) => (
                    <AnimatedCounter key={statistic.id} statistic={statistic} />
                ))}
            </Box>
        </div>
    )
}

export default LestOurStatistics