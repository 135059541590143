import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const CardOurFeatures = ({ srcImg, title, supTitle }) => {
    return (
        // <div>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: { md: '30%', xs: '100%' },
            alignItems: 'center',
            textAlign: 'center'
        }} >
            <Box sx={{
                border: '3px solid #F9AF33',
                padding: '5px',
                borderRadius: '50%'
            }} >
                <CardMedia
                    component="img"
                    height="250"
                    image={srcImg}
                    alt="green iguana"
                    sx={{
                        borderRadius: '50%',
                        width: '65px',
                        height: '65px',
                        padding: '8px',
                        backgroundColor: '#F9AF33'
                    }}
                />
            </Box>
            <Typography sx={{ color: '#000' }} >{title}</Typography>
            <Typography sx={{ color: '#939494' }} >{supTitle}</Typography>
        </Box>
        // {/* </div> */ }
    )
}

export default CardOurFeatures