import React from 'react'
import Section from '../Ui/Section'
import { useTranslation } from 'react-i18next'
import ListReviews from './ListReviews'

const Reviews = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Section title={t("Reviews.title")} >
                <ListReviews />
            </Section>
        </div>
    )
}

export default Reviews