import React from 'react'
import Header from '../components/Ui/Header'
import { useTranslation } from 'react-i18next'
import Contact from '../components/contactUs/Contact'
import Section from '../components/Ui/Section'

const ContactUs = () => {
    const { t } = useTranslation()
    return (
        <>
            <Header title={t("linksPages.ContactUs")} />
            <Section>
                <Contact />
            </Section>
        </>
    )
}

export default ContactUs