import React from 'react'
import Carousel from 'react-material-ui-carousel'
import a1 from './../../assets/AlbumV/VID-20240104-WA0002.mp4'
import a2 from './../../assets/AlbumV/VID-20240104-WA0003.mp4'
import a3 from './../../assets/AlbumV/VID-20240104-WA0004.mp4'
import a4 from './../../assets/AlbumV/VID-20240104-WA0005.mp4'
import a5 from './../../assets/AlbumV/VID-20240104-WA0007.mp4'
import a6 from './../../assets/AlbumV/VID-20240106-WA0003.mp4'
import a7 from './../../assets/AlbumV/VID-20240106-WA0004.mp4'
import a8 from './../../assets/AlbumV/VID-20240106-WA0005.mp4'
import a9 from './../../assets/AlbumV/VID-20240106-WA0006.mp4'
import a10 from './../../assets/AlbumV/VID-20240106-WA0007.mp4'
import a11 from './../../assets/AlbumV/c1.mp4'
import a12 from './../../assets/AlbumV/c2.mp4'
import a13 from './../../assets/AlbumV/c3.mp4'
import a14 from './../../assets/AlbumV/c4.mp4'
import a15 from './../../assets/AlbumV/c5.mp4'
const AlbumV = () => {
    return (
        <>
            <Carousel
                height={400}
                autoPlay={false}
                sx={{
                    marginTop: "25px",
                    width: { md: "100%", xs: "100%" },
                    margin: "auto"
                }}>

                <video autoPlay={false} controls={true} src={a11} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>
                <video autoPlay={false} controls={true} src={a12} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>
                <video autoPlay={false} controls={true} src={a13} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>
                <video autoPlay={false} controls={true} src={a14} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>
                <video autoPlay={false} controls={true} src={a15} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a1} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a2} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a3} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a4} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a5} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a6} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a7} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a8} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a9} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>

                <video autoPlay={false} controls={true} src={a10} loop muted width={'100%'} height={'100%'} style={{ objectFit: 'fill' }} >
                    Your browser does not support the video tag.
                </video>
            </Carousel>
        </>
    )
}

export default AlbumV