import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const ServiceFooter = () => {
    const { t } = useTranslation()
    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
            <Typography sx={{ color: '#000', my: 1 }} variant='h6' >
                {t("Footer.tit2")}
            </Typography>
            <Typography>
                <Link
                    to="/services-move"
                    style={{
                        color: "#fff",
                        textDecoration: "none",
                        fontSize: 18,
                        fontWeight: "bold",
                    }}
                >
                    {t('Service.service1')}
                </Link>
            </Typography>
            <Typography>
                <Link
                    to="/services-storage"
                    style={{
                        color: "#fff",
                        textDecoration: "none",
                        fontSize: 18,
                        fontWeight: "bold",
                    }}
                >
                    {t('Service.service2')}
                </Link>
            </Typography>
        </Box>
    )
}

export default ServiceFooter