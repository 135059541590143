import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const CardService = ({ srcImg, title, supTitle, path }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            width: { md: '45%', xs: '100%' }, height: '100%',
            justifyContent: 'space-between',
            borderRadius: '18px',
        }} >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="250"
                    image={srcImg}
                    alt="green iguana"
                    sx={{ borderRadius: '18px', objectFit: 'fill' }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{ color: '#B8232D' }} >
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ color: '#939494' }} >
                        {supTitle}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary"
                    sx={{
                        backgroundColor: '#F9AF33',
                        color: '#000',
                        "&:hover": {
                            backgroundColor: '#F9AF33', color: "#000",
                        },
                        py: 1,
                        px: 2
                    }}
                    onClick={() => navigate(path)}
                >
                    {t("sliderText.ReadMore")}
                </Button>
            </CardActions>
        </Card>
    )
}

export default CardService