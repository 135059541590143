import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Form from './Form'

const Contact = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box my={4} >
                <Typography variant="h5"
                    component="h4"
                    sx={{
                        mb: 2,
                        fontWeight: "null",
                        color: "#FFC82D",
                        textAlign: "center"
                    }} >{t("ContactUs.title")}</Typography>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'space-around' }} >
                    <Form />
                    {/* map */}
                    <Box sx={{ width: { md: '45%', xs: '100%' } }} >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30056434.552269254!2d63.33251388828143!3d23.133735265219432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5bc18d084b9e1af%3A0x8204de30e5530040!2z2LTYsdmD2Kkg2KfZhNis2YXYp9mEINin2YTYs9ix2YrYudipINmE2YTZhtmC2YQg2KfZhNio2LHZig!5e0!3m2!1sar!2seg!4v1704375700369!5m2!1sar!2seg"
                            width="100%"
                            title='camal'
                            style={{ border: 'none' }}
                            height="450"
                            allowfullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Box>
                </Box>
            </Box>

        </div >
    )
}

export default Contact