import React from 'react'
import Section from '../Ui/Section'
import LestOurStatistics from './LestOurStatistics'
import { useTranslation } from 'react-i18next'

const OurStatistics = () => {

    const { t } = useTranslation()
    return (

        <div style={{ backgroundColor: '#FAFAFA', padding: '16px 0' }} >
            <Section title={t("OurStatistics.title")} >
                <LestOurStatistics />
            </Section>
        </div>
    )
}

export default OurStatistics