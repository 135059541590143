import { Avatar, Box, Stack } from '@mui/material'
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const SotialFooter = () => {
    return (
        <>
            <Stack direction="column" spacing={2} position="fixed" sx={{
                zIndex: "9999",
                left: "20px",
                top: 'auto',
                bottom: 4
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { md: 3, xs: 1 }, justifyContent: { md: 'center', xs: 'space-evenly' }, pb: { md: 4, xs: 1 }, mt: 2 }}>
                    <a href="https://whas.me/ZWBuziiCXq"
                        target='_blank' rel='noreferrer'>
                        <Avatar sx={{ width: "50px", height: "50px", backgroundColor: "#25D366" }} >
                            <WhatsAppIcon sx={{ fontSize: '2.5rem' }} />
                        </Avatar>
                        {/* <WhatsAppIcon sx={{ color: 'green', fontSize: '2.5rem' }} /> */}
                    </a>
                </Box>
            </Stack>
        </>
    )
}

export default SotialFooter