import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const AboutFooter = () => {
    const { t } = useTranslation()
    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} >
            <Typography sx={{ color: '#000', my: 1 }} variant='h6' >
                {t("Footer.tit1")}
            </Typography>
            <Typography>

                <Link
                    to="/about"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 18,
                        fontWeight: "bold",
                    }}
                >
                    {t('linksPages.aboutUs')}
                </Link>
            </Typography>
            <Typography>
                <Link
                    to="/contact"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 18,
                        fontWeight: "bold",
                    }}
                >
                    {t('linksPages.ContactUs')}
                </Link>
            </Typography>
        </Box>
    )
}

export default AboutFooter