import React from 'react'
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import logo from "./../../assets/logo (3).png"
import ServiceFooter from './ServiceFooter';
import AboutFooter from './AboutFooter';
import ContactFooter from './ContactFooter';
import SotialFooter from './SotialFooter';

const Footer = () => {
    return (
        <footer style={{ padding: '20px', background: '#F9AF33' }} >
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: "center" }} >
                        <SotialFooter />
                        <ContactFooter />
                    </Box>
                </Grid>
                <Grid item md={3} xs={12}>
                    <ServiceFooter />
                </Grid>
                <Grid item md={2} xs={12}>
                    <AboutFooter />
                </Grid>
                <Grid item md={3} xs={12}>
                    <Box sx={{
                        width: { md: '100%', xs: '75%' },
                        margin: 'auto'
                    }} >
                        <img src={logo} style={{ width: '100%' }} alt="logo"></img>
                    </Box>
                </Grid>
            </Grid>
        </footer>
    )
}

export default Footer