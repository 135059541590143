import { Box } from '@mui/material'
import React from 'react'
import CardReview from './CardReview'
import { reviews } from '../../constants'
import i18next from 'i18next'

const ListReviews = () => {
    const lng = i18next.language
    return (
        <div>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap', gap: 3.2,
                justifyContent: 'space-around'
            }} >
                {
                    reviews.map((feature) =>
                        <CardReview
                            srcImg={lng === 'ar' ? feature.imageUrl : feature.imageUrlE}
                        />
                    )}
            </Box>
        </div>
    )
}

export default ListReviews