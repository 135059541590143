import { Box } from '@mui/material'
import React from 'react'
import { services } from '../../constants'
import CardService from './CardService'
import i18next from 'i18next'

const ListCardService = () => {
    let lng = i18next.language
    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
        }} >
            {
                services.map((service) =>
                    <CardService
                        srcImg={service.imageUrl}
                        title={lng === 'en' ? service.title : service.titleAr}
                        supTitle={lng === 'en' ? service.supTitle : service.supTitleAr}
                        path={service.path}
                    />
                )}
        </Box>
    )
}

export default ListCardService