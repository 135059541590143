import { Box, CardMedia } from '@mui/material'
import React from 'react'
import msnge from '../../assets/Social/insta.svg'
import whatsApp from '../../assets/Social/Icon awesome-whatsapp.png'
import facepook from '../../assets/Social/Icon awesome-facebook-f.png'
const SotialFooter = () => {
    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
                <Box  >
                    <a href="https://www.instagram.com/camelexpress_ksa?igsh=MW8xYnYyY3dnYTFuMg==" target='_blank' rel='noreferrer'>
                        <CardMedia
                            component="img"
                            image={msnge}
                            alt="green iguana"
                            sx={{
                                width: '30px',
                                height: '30px',
                                objectFit: 'contain'
                            }}
                        />
                    </a>
                </Box>
                <Box sx={{ background: '#11C21D', borderRadius: 1, width: 'fit-content' }} >
                    <a href="https://whas.me/ZWBuziiCXq" target='_blank' rel='noreferrer'>
                        <CardMedia
                            component="img"
                            image={whatsApp}
                            alt="green iguana"
                            sx={{
                                borderRadius: '50%',
                                width: '30px',
                                height: '30px',
                                padding: '4px',
                                objectFit: 'contain'
                            }}
                        />
                    </a>
                </Box>
                <Box sx={{ background: '#1976D2', borderRadius: 1, width: 'fit-content' }} >
                    <a href="https://www.facebook.com/profile.php?id=100092106621955&mibextid=LQQJ4d" target='_blank' rel='noreferrer'>

                        <CardMedia
                            component="img"
                            image={facepook}
                            alt="green iguana"
                            sx={{
                                borderRadius: '50%',
                                width: '30px',
                                height: '30px',
                                padding: '4px',
                                objectFit: 'contain'
                            }}
                        />
                    </a>
                </Box>
            </Box>
        </div>
    )
}

export default SotialFooter