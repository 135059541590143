import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from '@mui/material';
import i18next from 'i18next';

export default function BasicSelect() {

    const lngs = {
        en: { nativeName: 'En' },
        ar: { nativeName: 'Ar' }
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { i18n } = useTranslation()
    let lng = i18next.language
    return (
        <div>
            <Box textAlign={'end'} width='fit-content' display={'flex'} >
                <Typography
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        cursor: "pointer",
                        px: { md: 3, xs: 0 },
                        color: { md: '#FFF', xs: '#000' },
                    }}
                    id="basic-button"

                >
                    {lng === 'en' ? 'En' : 'Ar'}
                </Typography>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}>
                    {Object.keys(lngs).filter((lngKey) => lngKey !== lng).map((otherLng) => (
                        <MenuItem key={otherLng}
                            style={{
                                fontWeight: i18n.resolvedLanguage === otherLng ? 'bold' : 'normal',
                                zIndex: 99999999999999999999999999999999999999999999999999999,
                            }}
                            type="submit"
                            value={otherLng}
                            onClick={() => { i18n.changeLanguage(otherLng); handleClose() }}>
                            <Typography>
                                {lngs[otherLng].nativeName}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>

            </Box>
        </div>
    );
}