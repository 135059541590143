import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import './Form.css'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from "notistack";
const Form = () => {
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const { t } = useTranslation()
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_yz9rr2c', 'template_0ojs8ct', form.current, 'k4cLB_m0LEmIWrroM')
            .then((result) => {
                console.log(result.text);

                enqueueSnackbar("تم ارسال البيانات بنجاح")

            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <Box sx={{
            p: 2,
            borderRadius: '8px',
            position: 'relative',
            width: { md: '45%', xs: '100%' }
        }} >
            <form className='myForm' ref={form} onSubmit={sendEmail}>
                <label htmlFor="name">{t("ContactUs.Name")}</label>
                <input id='name' type="Name" name="Name"
                    placeholder={t("ContactUs.Yourname")} required />
                <label htmlFor="phone">{t("ContactUs.Phone")}</label>
                <input id='phone' type="phone" name="phone"
                    placeholder={t("ContactUs.Yournumber")} required />
                <label htmlFor="service">{t("ContactUs.service")}</label>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="service"
                        value={value}
                        onChange={handleChange}
                        required
                    >
                        <FormControlLabel value={t("Service.service1")} control={<Radio sx={{
                            '&.Mui-checked': {
                                color: '#FFC82D',
                            },
                        }} />} label={t("Service.service1")} />
                        <FormControlLabel value={t("Service.service2")} control={<Radio sx={{
                            '&.Mui-checked': {
                                color: '#FFC82D',
                            },
                        }} />} label={t("Service.service2")} />
                    </RadioGroup>
                </FormControl>
                <a href="https://whas.me/ZWBuziiCXq"
                    target='_blank' rel='noreferrer'  >
                    <Button
                        type='submit'
                        sx={{
                            backgroundColor: '#F9AF33', color: "#000",
                            width: '10rem'
                            , "&:hover": {
                                backgroundColor: '#F9AF33',
                                color: '#000',
                            },
                        }} >
                        {t("ContactUs.Send")}
                    </Button>
                </a>
            </form>
        </Box>
    )
}

export default Form