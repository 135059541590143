import { Box, Typography } from '@mui/material'
import React from 'react'
import SliderService from '../SliderService/SliderService'

const Services = ({ title, desc1, children, sliderItem }) => {

    return (
        <Box sx={{
            marginTop: '60px',
            marginBottom: 2,
            display: 'flex',
            flexDirection: { md: 'row-reverse', xs: 'column' },
            gap: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
        }} >
            <SliderService>
                {sliderItem}
            </SliderService>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }} >
                <Typography variant='h5' sx={{ color: '#000' }}>{title}</Typography>
                <Typography sx={{ color: '#939494' }} >{desc1}</Typography>
                {children}
            </Box>
        </Box>
    )
}

export default Services