import React from 'react'
import Header from '../components/Ui/Header'
import { useTranslation } from 'react-i18next'
import SectionAbout from '../components/Ui/SectionAbout'
import about from './../assets/aboutUs/88.png'
import goal from './../assets/aboutUs/Group 99.png'
import Vision from './../assets/aboutUs/Group 102.png'
const AboutUs = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Header title={t("linksPages.aboutUs")} />
            <SectionAbout
                derRow={`row-reverse`}
                derCol={`column`}
                srcImg={about}
                title={t("AboutUs.About.title")}
                desc1={t("AboutUs.About.desc1")}
                desc2={t("AboutUs.About.desc2")} />
            <SectionAbout
                derRow={`row`}
                derCol={`column`}
                srcImg={goal}
                title={t("AboutUs.OurAoal.title")}
                desc2={t("AboutUs.OurAoal.desc2")}
                desc3={t("AboutUs.OurAoal.desc2")}
                background={''} />
            <SectionAbout
                derRow={`row-reverse`}
                derCol={`column`}
                srcImg={Vision}
                title={t("AboutUs.OurVision.title")}
                desc2={t("AboutUs.OurVision.desc2")}
                desc3={t("AboutUs.OurVision.desc3")}
                desc4={t("AboutUs.OurVision.desc4")}
            />
        </div>
    )
}

export default AboutUs