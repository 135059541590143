import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TransLang from "./TransLang"

export default function NavDrawer({ setDrawer, drawer }) {
    const { t } = useTranslation()
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}
                        onClose={() => setDrawer(false)}
                    >
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.home')}
                        </Link>
                        <Link
                            to="/about"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.aboutUs')}
                        </Link>
                        <Link
                            to="/services-move"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            {t('Service.service1')}
                        </Link>
                        <Link
                            to="/services-storage"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            {t('Service.service2')}
                        </Link>
                        <Link
                            to="/contact"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            {t('linksPages.ContactUs')}
                        </Link>

                        <TransLang />
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
