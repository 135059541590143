import React from 'react'
import Section from '../Ui/Section'
import { useTranslation } from 'react-i18next'
import ListCardService from './ListCardService'

const Service = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Section title={t("Service.OurService")} >
                <ListCardService />
            </Section>
        </div>
    )
}

export default Service