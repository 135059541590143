import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
const FreeInspection = () => {
    let lng = i18next.language
    const { t } = useTranslation()
    return (
        <div>
            <Box sx={{
                backgroundColor: "#F9AF33",
                borderRadius: 1,
                transform: 'rotate(270deg)',
                py: 1,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                position: 'fixed', top: '50%',
                right: { md: lng === 'en' ? '-3%' : '94%', xs: lng === 'en' ? '-12%' : '75%' },
                zIndex: 9999999999,
                width: '125px',
                textAlign: 'center'
            }} >
                <Typography sx={{ color: '#000' }}>
                    <a href="tel:920007123" style={{ color: '#000', textDecoration: "none" }} >
                        {t("FreeInspection")}
                    </a>
                </Typography>
            </Box>
        </div>
    )
}

export default FreeInspection