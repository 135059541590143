import React, { useState, useEffect } from 'react';
import CardOurStatistics from './CardOurStatistics';
import i18next from 'i18next';

const AnimatedCounter = ({ statistic }) => {
    const [count, setCount] = useState(0);
    const goal = parseInt(statistic.supTitle);
    let lng = i18next.language
    useEffect(() => {
        let interval;
        if (count < goal) {
            interval = setInterval(() => {
                setCount((prevCount) => prevCount + 1);
            }, 1000 / goal);
        }
        return () => clearInterval(interval);
    }, [count, goal]);

    return (
        <CardOurStatistics
            srcImg={statistic.imageUrl}
            title={lng === 'en' ? statistic.title : statistic.titleAr}
            supTitle={count.toString()}
        />
    );
};

export default AnimatedCounter;
